<template>
  <div class="content" >
    <base-header class="pb-6">
      <div class="row align-items-center py-2">
        <div class="col-lg-6 col-7"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card>
        <!-- Card header -->
        <h2 slot="header" class="mb-0" v-if="certificate_id">
          Edit New Certificate
        </h2>
        <h2 slot="header" class="mb-0" v-else>
          Add New Certificate
        </h2>
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit()"
            enctype="multipart/form-data"
          >
            <div class="form-row">
              <div class="col-md-6 ">
                <base-input
                  label="Certificate Name"
                  name="Certificate Name"
                  placeholder="Certificate Name"
                  rules="required"
                  v-model="certificate.certificate_name"
                >
                </base-input>
              </div>
              <div class="col-md-6 form-group">
                <label class="form-control-label">Template</label>
               
                <el-select
                  v-model="template"
                  class="select-primary select-template"
                  label="Template"
                  rules="required"
                >
                  <el-option
                    v-for="(option, index) in templates"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="'template_' + index"
                  >
                  </el-option>
                </el-select>
              </div>
              
            </div>
            <div class="form-row" v-if="template==7"> 
              <div class="col-md-12 form-group">
               <label class="form-control-label">Content</label>
                <textarea class="form-control" v-model="certificate.content_emp" ></textarea>
              </div>
              <!-- <div class="col-md-12 form-group">
               <label class="form-control-label">Content For Manager</label>
                <textarea class="form-control" v-model="certificate.content_man" ></textarea>
              </div> -->
            </div>
            <div class="text-right mt-3">
              <base-button
                class="custom-btn"
                type="danger"
                @click="$router.go(-1)"
                >Cancel</base-button
              >
              <base-button
                class="custom-btn"
                @click.prevent="saveCertificates()"
                >{{ certificate_id !== "" ? " Update" : "Submit" }}</base-button
              >
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      hot_user: "",
      hot_token: "",
      config: "",
      datePicker: "",
      certificate_id: "",
      certificate: {
        certificate_name: "",
        content_emp:"",
        content_man:"",
        course_id: "",
      },
      templates: [],
      template: "",
    };
  },
  created() {
    if (localStorage.getItem("hot-token")) {
      this.hot_user = localStorage.getItem("hot-user");
      this.hot_token = localStorage.getItem("hot-token");
    }

    this.config = {
      headers: {
        authorization: "Bearer " + localStorage.getItem("hot-token"),
        "content-type": "application/json"
      }
    };
    this.$http.get("course/templates").then(resp => {
      for (let template of resp.data) {
        let obj = {
          label: template.name,
          value: template.id
        };
        this.templates.push(obj);
      }
    });

    if (this.$route.query.id) {
      this.certificate_id = this.$route.query.id;
      this.$http
        .get("course/certificate/" + this.certificate_id, this.config)
        .then(resp => {
          let data = resp.data;
          this.certificate.certificate_name = data.name;
          this.certificate.content_emp = data.content_for_employee;
          this.certificate.content_man = data.content_for_manager;
          this.certificate.course_id = data.course_id;
          this.template = data.template_id;
        });
    }
  },
  methods: {
    saveCertificates() {
      if (this.certificate_id !== "") {
        this.$http
          .put(
            "course/certificate/" + this.certificate_id,
            {
              certificate_id: this.certificate_id,
              course_certificate_name: this.certificate.certificate_name,
              course_certificate_content_emp: this.certificate.content_emp,
              course_certificate_content_man: this.certificate.content_man,
              course_certificate_template: this.template,
            },
            this.config
          )
          .then(resp => {
            Swal.fire({
              title: "Success!",
              text: resp.data.message,
              icon: "success"
            });
            this.$router.push("/certificates");
          })
          .catch(function(error) {
            if (error.response.status === 422) {
              return Swal.fire({
                title: "Error!",
                text: error.response.data.message,
                icon: "error"
              });
            }
          });
      } else {
        this.$http
          .post(
            "course/certificate",
            {
              course_certificate_name: this.certificate.certificate_name,
              course_certificate_content_emp: this.certificate.content_emp,
              course_certificate_content_man: this.certificate.content_man,
              course_certificate_template: this.template,
            },
            this.config
          )
          .then(resp => {
            Swal.fire({
              title: "Success!",
              text: `Certificate Has been Added!`,
              icon: "success"
            });
            this.$router.push("/certificates");
          })
          .catch(function(error) {
            if (error.response.status === 422) {
              return Swal.fire({
                title: "Error!",
                text: error.response.data.message,
                icon: "error"
              });
            }
          });
      }
    }
  }
};
</script>
<style>
.stripe-card {
  border: 1px solid grey;
}
.stripe-card.complete {
  border-color: green;
}
.logo-size {
  width: 60%;
  height: auto;
}
.step_title_certi {
  color: rgb(23 43 77);
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  line-height: 35px;
}
</style>
